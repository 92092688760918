<template>
  <div class="h-100">
    <div class="h-100" style="display: flex; flex-direction: column">
      <div
        class="d-flex align-items-center justify-content-between"
        style="padding: 0 2% 0 1.5%"
      >
        <p class="m-0 TitleTop">Notifications</p>
        <div class="d-flex sectionTitle">
          <span
            style="
              color: #344054;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            "
            >Turn On / Off Notifications :
          </span>
          <span class="custom-control mx-2 custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`AnimationSwitch-${Date.now()}`"
              v-model="leadNotification.status"
            />
            <label
              class="custom-control-label"
              :for="`AnimationSwitch-${Date.now()}`"
            ></label>
          </span>
        </div>
      </div>

      <div
        class="flex-grow-1"
        style="
          padding: 15px 2% 15px 1.5%;
          max-height: calc(100vh - 185px);
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <div v-if="isLoading">
          <!-- <LoaderComp /> -->
          <div class="container mt-5">
            <TranslationSkeleton v-for="index in 5" :key="index" class="mb-4" />
          </div>
        </div>

        <div v-else class="">
          <div class="mt-3 LanguageContainer d-flex flex-column">
            <!-- Header start -->

            <!-- End Section start -->
            <div class="text-left d-flex flex-column flex-grow-1 px-3 py-2">
              <div class="w-100">
                <div>
                  <div class="row mb-3">
                    <div class="col-12 d-flex align-items-start">
                      <div style="flex: 0.5">
                        <p
                          class="mb-1"
                          style="
                            color: #1f1f28;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                          "
                        >
                          Receiver Email
                        </p>
                        <span
                          style="
                            color: #667085;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 142.857% */
                          "
                          >Notifications will be sent to this email
                          address.</span
                        >
                      </div>
                      <div class="" style="flex: 0.5; width: 100%">
                        <div
                          class="d-flex align-items-center mb-3"
                          v-for="(
                            recieverEmail, index
                          ) in leadNotification.emails"
                          :key="index"
                        >
                          <div
                            class="receiverEmailInput d-flex align-items-center mx-1 px-3 py-1"
                          >
                            <div style="cursor: pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M18.3333 4.99992C18.3333 4.08325 17.5833 3.33325 16.6667 3.33325H3.33332C2.41666 3.33325 1.66666 4.08325 1.66666 4.99992M18.3333 4.99992V14.9999C18.3333 15.9166 17.5833 16.6666 16.6667 16.6666H3.33332C2.41666 16.6666 1.66666 15.9166 1.66666 14.9999V4.99992M18.3333 4.99992L9.99999 10.8333L1.66666 4.99992"
                                  stroke="#667085"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <input
                              type="text"
                              class="mx-2 flex-grow-1"
                              v-model="recieverEmail.email"
                              placeholder="john@doe.com"
                            />
                          </div>
                          <div class="ml-3">
                            <button
                              type="button"
                              class="btn deleteButton"
                              @click="deleteEmail(index)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z"
                                  fill="#73738D"
                                  fill-opacity="0.5"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div class="mt-3">
                          <button
                            @click="addNewEmail"
                            class="btn d-flex aling-items-center"
                            style="
                              color: #0397cc;

                              /* Text sm/Medium */
                              font-family: Poppins;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: 20px; /* 142.857% */
                            "
                          >
                            <svg
                              class="mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                                fill="#0397CC"
                              />
                            </svg>
                            {{
                              leadNotification.emails.length > 0
                                ? "Add one more"
                                : "Add new"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Section End -->
          </div>
        </div>
      </div>

      <div
        class="p-2 d-flex justify-content-end"
        style="
          border-top: 1px solid #eaecf0;
          background: #fff;
          box-shadow: 0px -4px 11px 0px rgba(31, 31, 40, 0.07);
        "
      >
        <button
          class="btn text-white mx-2 mt-2"
          style="
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            border-radius: 6px;
            background: #4d1b7e;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          "
          @click="updateNotificationSetting()"
        >
          <span v-if="isUpdateLoading">
            <b-spinner small></b-spinner> Saving...
          </span>
          <span v-else>
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M14.697 8.03033C14.9899 7.73744 14.9899 7.26256 14.697 6.96967C14.4041 6.67678 13.9292 6.67678 13.6363 6.96967L14.697 8.03033ZM8.33332 13.3333L7.80299 13.8637C8.09588 14.1566 8.57075 14.1566 8.86365 13.8637L8.33332 13.3333ZM6.36361 10.303C6.07072 10.0101 5.59584 10.0101 5.30295 10.303C5.01006 10.5959 5.01006 11.0708 5.30295 11.3637L6.36361 10.303ZM13.6363 6.96967L7.80299 12.803L8.86365 13.8637L14.697 8.03033L13.6363 6.96967ZM5.30295 11.3637L7.80299 13.8637L8.86365 12.803L6.36361 10.303L5.30295 11.3637ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25Z"
                fill="white"
              />
            </svg>
            Save Changes</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import LoaderComp from "../component/LoaderComp.vue";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
// import JumpLogicSkeleton from "./JumpLogicSkeleton.vue"
export default {
  components: {
    // LoaderComp,
    TranslationSkeleton: () =>
      import(/* webpackChunkName: "JumpLogic" */ "./TranslationSkeleton.vue"),
  },
  data() {
    return {
      loadingSpinner: false,
      isLoading: false,
      leadNotification: {
        status: false,
        emails: [],
      },

      isUpdateLoading: false,
    };
  },

  computed: {
    ...mapGetters(["GetTokenFromMetaTag", "getQuizID", "getPlanData"]),
    getUserPlan() {
      return this.getPlanData.name;
    },
    isUserPlanValid() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      return allowedPlan.includes(this.getUserPlan);
    },
    currReciverEmailLength() {
      return this.leadNotification?.emails.length || 0;
    },
    getAllEmailsAsString(){
      if(this.leadNotification?.emails.length){
        return this.leadNotification?.emails.map(x=>x.email)
      }
      return []
    },
  },
  methods: {
    deleteEmail(index) {
      if (index >= 0 && index < this.leadNotification.emails.length) {
    this.leadNotification.emails.splice(index, 1);
    this.$toasted.show("Email deleted successfully!", {
      theme: "toasted-primary",
      position: "bottom-center",
      duration: 2000,
    });
  } else {
    this.$toasted.show("Error occured , Please try again!", {
      theme: "toasted-primary",
      type:'error',
      position: "bottom-center",
      duration: 2000,
    });
  }
    },
    addNewEmail() {
      const maxLength = this.isUserPlanValid ? this.getUserPlan == 'Plus' ? 3 : 10 : 1;

      if (this.currReciverEmailLength < maxLength) {
        this.leadNotification.emails.push({
          id: this.currReciverEmailLength + 1,
          email: ``,
        });
      } else {
        let obj = {
          isOpen: true,
          title: "Upgrade to Add More Emails",
  subText: "You have reached your email recipient limit. To add new emails, please upgrade your plan."
        };

        this.setUpgradePlansModal(obj);
      }
    },
    ...mapMutations(["setUpgradePlansModal"]),

    chageOnToggle() {
      if (!this.leadNotification.status) this.updateNotificationSetting();
    },
    async getLeadNotificationSetting() {
      const quizID = JSON.parse(localStorage.getItem("QuizID"));
      this.isLoading = true;
      try {
        const response = await axios.get(
          `quiz/${quizID}/lead-notifications/settings`
        );

        if (response.status == 200) {
          this.leadNotification.status = response.data.data.status;
          const emails = response.data.data.emails.map((email, index) => {
            return {
              id: index + 1,
              email: email,
            };
          });

          this.leadNotification.emails = emails;
        }
      } catch (error) {
        if (error || error.response) {
          this.$toasted.show("Error occurred", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async updateNotificationSetting() {
      
      const payload = JSON.parse(JSON.stringify(this.leadNotification))
      payload['emails'] = this.getAllEmailsAsString

      const quizID = JSON.parse(localStorage.getItem("QuizID"));
       
      try {
        this.isUpdateLoading = true;
        const response = await axios.put(
          `quiz/${quizID}/lead-notifications/settings`,
          payload
        );

        if(response.status == 200){
          this.$toasted.show("Settings saved successfully!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.getLeadNotificationSetting();
        }

      } catch (error) {
        if (error || error.response) {
          const Errors = error.response.data.errors;

          for (const field in Errors) {
            if (Object.prototype.hasOwnProperty.call(Errors, field)) {
              const errorMessages = Errors[field];
              errorMessages.forEach((errorMessage) => {
                this.$toasted.show(errorMessage, {
                  theme: "toasted-primary",
                  type:'error',
                  position: "bottom-center",
                  duration: 3000,
                });
              });
            }
          }
        }
      } finally {
        this.isUpdateLoading = true;
      }

      this.isUpdateLoading = false;
    },
  },
  mounted() {
    this.getLeadNotificationSetting();
  },
};
</script>

<style scoped>
.TitleTop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #1f1f28;
}

.receiverEmailInput {
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #1f1f28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
  width: 300px;
}

.receiverEmailInput input {
  background: transparent;
  border: none;
  outline: none;
}

.receiverEmailInput input:focus {
  border: none;
  outline: none;
}
</style>
